'use client'
import styles from '@/src/components/Button/RoundedButton/RoundedButton.module.scss'
import { gtmClick } from '@/src/services/gtm_factory.ts'
import { GTMClickEventData } from '@/src/services/types/gtm'
import classNames from 'classnames'
import React, { useCallback } from 'react'
import { AiOutlineReload } from 'react-icons/ai'

export type RoundedButtonProps = {
  animation?: string | null
  children: React.ReactNode
  className?: string
  onClick?: (_event: React.MouseEvent<HTMLElement>) => void
  onTouchStart?: (_event: React.TouchEvent<HTMLElement>) => void
  isSubmit?: boolean
  size?: 'tiny' | 'small' | 'default' | 'large'
  type?: 'primary' | 'secondary' | 'secondary-glow' | 'third' | 'rg'
  warning?: boolean
  disabled?: boolean
  form?: string
  style?: React.CSSProperties
  gtm?: GTMClickEventData
  allowDisable?: boolean
  tabIndex?: number
  icon?: React.ReactNode
  fullWidth?: boolean
  progress?: boolean
}
const RoundedButton = ({
  animation = null,
  children,
  className,
  onClick,
  onTouchStart,
  isSubmit,
  size = 'default',
  type = 'primary',
  warning = false,
  disabled,
  style,
  gtm,
  allowDisable = true,
  icon,
  fullWidth,
  progress = false,
  ...rest
}: RoundedButtonProps) => {
  const classString = classNames(
    // set classes here to use tailwind merge. don't use @apply for this scenario
    'relative rounded-md px-7 py-2 text-lg font-bold uppercase select-none',
    styles.button,
    styles[`button-${type}`],
    { [styles[`button-rg-red`]]: warning },
    styles[`button-${size}`],
    animation && styles[`button-${animation}`],
    { [styles.disabled]: disabled || progress },
    { [styles.iconButton]: !!icon },
    { [styles.fullWidth]: fullWidth },
    className,
  )

  const handleButtonClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (onClick && e.type === 'click') onClick(e)
      if (gtm) gtmClick(gtm)
    },
    [onClick, gtm],
  )

  const handleButtonTouch = useCallback(
    (e: React.TouchEvent<HTMLElement>) => {
      e.preventDefault()
      if (onTouchStart) onTouchStart(e)
      if (gtm) gtmClick(gtm)
    },
    [gtm, onTouchStart],
  )

  return (
    <button
      className={classString}
      onTouchStart={!!onTouchStart ? handleButtonTouch : undefined}
      onClick={handleButtonClick}
      style={style}
      type={isSubmit ? 'submit' : 'button'}
      disabled={allowDisable && (disabled || progress)}
      {...rest}
    >
      {progress && <AiOutlineReload className={styles.progressIcon} />}
      {icon && <span className={classNames(styles.icon)}>{icon}</span>}
      <span className={styles.text}>{children}</span>
    </button>
  )
}

export default RoundedButton
