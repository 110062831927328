import { PlayerType } from '@/src/services/types'
import { IntercomTokenType } from '@/src/services/types/player.tsx'
import {
  Intercom,
  boot,
  onShow,
  show,
  shutdown,
} from '@intercom/messenger-js-sdk'

/* eslint-disable @typescript-eslint/no-explicit-any */
type FirstArgument<T> = T extends (arg1: infer U, ...args: any[]) => any
  ? U
  : never
/* eslint-enable @typescript-eslint/no-explicit-any */

type ExtractedInitType = FirstArgument<typeof Intercom>

const intercomObj: ExtractedInitType = {
  app_id: 'upl859im',
  region: 'eu',
}

const fillUserFields = (user?: PlayerType, token?: IntercomTokenType) => {
  if (user?.userId && token?.token) {
    intercomObj.user_id = `${user.userId}`
    intercomObj.name = `${user.firstName} ${user.lastName}`
    intercomObj.email = user.email || undefined
    intercomObj.created_at = user.registrationTime
    intercomObj.user_hash = token?.token
    return
  }

  delete intercomObj.user_id
  delete intercomObj.name
  delete intercomObj.email
  delete intercomObj.created_at
  delete intercomObj.user_hash
}

const initiateIntercom = () => {
  Intercom(intercomObj)
}

const rebootIntercom = (user?: PlayerType, token?: IntercomTokenType) => {
  shutdown()
  fillUserFields(user, token)
  boot(intercomObj)
}

const checkIntercomLoaded = (retries = 10, interval = 500) => {
  return new Promise((resolve) => {
    let attempts = 0

    const check = () => {
      if (window?.Intercom) {
        resolve(true)
      } else {
        attempts++
        if (attempts < retries) {
          setTimeout(check, interval)
        } else {
          resolve(false)
        }
      }
    }

    check()
  })
}

const showIntercomWidget = () => show()
const onIntercomShow = (callback: () => void) => onShow(callback)

export {
  checkIntercomLoaded,
  initiateIntercom,
  onIntercomShow,
  rebootIntercom,
  showIntercomWidget,
}
