import styles from '@/src/components/Button/FlatButton/FlatButton.module.scss'
import { gtmClick } from '@/src/services/gtm_factory.ts'
import { GTMClickEventData } from '@/src/services/types/gtm'
import classNames from 'classnames'
import React from 'react'

interface FlatButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'tiny' | 'small' | 'default' | 'large'
  gtm?: GTMClickEventData
  variant?: 'primary' | 'secondary'
}

const FlatButton = ({
  children,
  className,
  onClick,
  size = 'default',
  disabled,
  style,
  gtm,
  variant,
  ...rest
}: FlatButtonProps) => {
  return (
    <button
      className={classNames(
        styles.button,
        styles[`button-size-${size}`],
        { [styles[`button-variant-${variant}`]]: !!variant },
        { [styles.disabled]: disabled },
        className,
      )}
      onClick={(e) => {
        if (onClick) onClick(e)
        if (gtm) gtmClick(gtm)
      }}
      style={style}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  )
}

const Round = (props: FlatButtonProps) => {
  return (
    <FlatButton
      {...props}
      className={classNames([styles.round, props.className?.split(' ')])}
    />
  )
}

FlatButton.Round = Round

export default FlatButton
