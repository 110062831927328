import styles from '@/src/components/Button/ListButton/ListButton.module.scss'
import { gtmClick } from '@/src/services/gtm_factory.ts'
import { GTMClickEventData } from '@/src/services/types/gtm'
import classNames from 'classnames'
import React from 'react'
import { BiChevronDown } from 'react-icons/bi'

type ListButtonProps = {
  children: React.ReactNode
  className?: string
  description?: string
  onClick?: (_event: React.MouseEvent<HTMLElement>) => void
  unchecked?: string | undefined
  type?: 'primary' | 'secondary' | 'third'
  disabled?: boolean
  form?: string
  style?: React.CSSProperties
  gtm?: GTMClickEventData
  allowDisable?: boolean
  isDropdown?: boolean
}
const ListButton = ({
  children,
  className,
  description,
  onClick,
  isDropdown,
  type = 'primary',
  unchecked,
  disabled,
  style,
  gtm,
  allowDisable = false,
}: ListButtonProps) => {
  return (
    <button
      className={classNames(
        styles.button,
        styles[`button-${type}`],
        styles['button--listButton'],
        { [styles['button--animate']]: !isDropdown },
        { [styles.disabled]: disabled },
        className,
      )}
      onClick={(e) => {
        if (onClick) onClick(e)
        if (gtm) gtmClick(gtm)
      }}
      style={style}
      typeof='button'
      disabled={allowDisable && disabled}
    >
      <div className='flex items-center'>
        <span
          className={classNames(
            styles.dot,
            styles[`dot--${unchecked || type}`],
            styles['dot'],
            { [styles.disabled]: disabled },
            className,
          )}
        />
      </div>
      <div className='flex w-full flex-col items-start truncate text-left'>
        {children}
        {description && (
          <div
            className={classNames(
              styles.description,
              styles[`description--${type}`],
              styles['description'],
              { [styles.disabled]: disabled },
              className,
              'pr-7',
            )}
          >
            <span className={'truncate'}>{description}</span>
          </div>
        )}
      </div>
      {isDropdown && <BiChevronDown className='ml-auto' />}
    </button>
  )
}

ListButton.displayName = 'ListButton'

export default ListButton
