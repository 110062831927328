import styles from '@/src/components/Button/FeatureButton/FeatureButton.module.scss'
import RoundedButton from '@/src/components/Button/RoundedButton'
import { FaFacebook, FaGoogle } from 'react-icons/fa'

type FeatureButtonType = {
  type: 'google' | 'facebook'
  isSubmitting: boolean
  children: React.ReactNode
}

const FeatureButton = ({ type, isSubmitting, children }: FeatureButtonType) => {
  return (
    <RoundedButton
      disabled={isSubmitting}
      className={`w-full ${styles[`button-${type}`]}`}
      size='large'
    >
      <div className='flex justify-center align-middle normal-case'>
        {type == 'google' && (
          <>
            <FaGoogle style={{ width: 30, height: 30 }} className='inline' />{' '}
            <div className='grow' style={{ marginLeft: -30 }}>
              {children}
            </div>
          </>
        )}
        {type == 'facebook' && (
          <>
            <FaFacebook style={{ width: 30, height: 30 }} className='inline' />{' '}
            <div className='grow' style={{ marginLeft: -30 }}>
              {children}
            </div>
          </>
        )}
      </div>
    </RoundedButton>
  )
}

FeatureButton.displayName = 'FeatureButton'

export default FeatureButton
