'use client'

import { showIntercomWidget } from '@/src/utils/intercome.ts'
import React from 'react'

const GlobalErrorSupport = ({ children }: { children: React.ReactNode }) => {
  const openSupport = async (e: React.MouseEvent) => {
    if (typeof window === 'undefined') return

    e.preventDefault()
    showIntercomWidget()
  }

  return (
    <a href='#' onClick={(e: React.MouseEvent) => openSupport(e)}>
      {children}
    </a>
  )
}

export default GlobalErrorSupport
