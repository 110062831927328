'use client'
import { RoundedButton } from '@/src/components/Button'
import StatusScreen from '@/src/components/StatusScreen'
import Heading from '@/src/components/Typography/Heading'
import { font } from '@/src/const/font'
import GlobalErrorSupport from '@/src/widgets/GlobalErrorSupport'
import { captureException } from '@sentry/nextjs'
import { useEffect } from 'react'

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    captureException(error)
  }, [error])

  return (
    <html>
      <head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </head>
      <body className={`${font.variable} font-sans`} id='app-main'>
        <StatusScreen>
          <Heading level={1}>Something went wrong!</Heading>
          <p className='mb-8 px-8 text-center opacity-80'>
            Something went wrong. Please contact support.
          </p>
          <RoundedButton
            onClick={() => reset()}
            type='primary'
            className='mb-6'
          >
            Retry
          </RoundedButton>
          <GlobalErrorSupport>Contact Us</GlobalErrorSupport>
        </StatusScreen>
      </body>
    </html>
  )
}
